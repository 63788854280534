import {
  AltenarEvent,
  AltenarEventShowMoreChampionshipEvents,
  CMSComponent,
  CMSComponentRef
} from '@/types/api/ge-strapi/cms-page';
import { getFullUrlByLicense, getPathByLicense } from '@/utils/multiDomains';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig() || {};
const { currentAppConfig } = publicRuntimeConfig;

export function getAltenarBaseSportUrl(locale: string) {
  if (currentAppConfig?.features?.customSportsHome) {
    return getPathByLicense('F', true).replace('/sports', '/sports-book');
  }

  return getFullUrlByLicense('F', locale);
}

export function getAltenarEventUrl(
  locale: string,
  event: AltenarEvent,
  isLive: boolean = false
) {
  const hash = `/sport/${event.sportId}/category/${
    event.catId
  }/championship/${event.championships.map((i) => i.id).join(',')}/${
    isLive ? 'liveEvent' : 'event'
  }/${event.id}`;

  return `${getAltenarBaseSportUrl(locale)}/#${hash}`;
}

export function navigateToAltenarEvent(
  locale: string | undefined = 'fr',
  event: AltenarEvent
) {
  window.location.href = getAltenarEventUrl(locale, event);
}

export function getAltenarChampionshipUrl(
  locale: string,
  event: AltenarEventShowMoreChampionshipEvents
) {
  const { championshipIds, categories, sport } = event;
  const hash = `/sport/${sport?.id ?? 0}/category/${
    categories?.[0]?.id ?? 0
  }/championship/${championshipIds?.join(',') ?? 0}/eventType/${
    event?.eventType ?? 'm'
  }`;

  return `${getAltenarBaseSportUrl(locale)}#${hash}`;
}

export function navigateToChampionship(
  locale: string | undefined = 'fr',
  event: AltenarEventShowMoreChampionshipEvents
) {
  window.location.href = getAltenarChampionshipUrl(locale, event);
}

export function getAltenarSportUrl(locale: string, sportId: number) {
  const hash = `/sport/${sportId}`;
  return `${getAltenarBaseSportUrl(locale)}/#${hash}`;
}

export function navigateToSport(
  locale: string | undefined = 'fr',
  sportId: number
) {
  window.location.href = getAltenarSportUrl(locale, sportId);
}

export function getAltenarBetHistoryUrl(locale: string) {
  const hash = `/betHistory`;
  return `${getAltenarBaseSportUrl(locale)}/#${hash}`;
}

export function navigateToAltenarBetHistory(locale: string | undefined = 'fr') {
  const url = getAltenarBetHistoryUrl(locale);
  window.location.href = url;
}
