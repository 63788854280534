import {
  ApiGameLaunchRequest,
  ApiGameLaunchResponse
} from '@/components/Button/PlayNowButton';
import { useAuth } from '@/context/Auth';
import { Button, useMediaQuery, useToast } from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { HTTP } from '@/components';
import { UserLicencesType } from '@/services/handleAuth';
import { useRouter } from 'next/router';

export const altenarLanguageCodes: {
  [key: string]: string;
} = {
  en: 'en-GB',
  fr: 'fr-FR',
  nl: 'nl-NL'
};

type AltenarConfig = {
  integration: string;
  scriptSrc: string;
  numFormat?: string;
  oddsFormat?: number;
  enabled: boolean;
  gameId: string;
};

const debug = false;

export function useAddWidget(
  key: string,
  addWidget: (ref: MutableRefObject<HTMLDivElement | null>) => void
) {
  const altenarContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (window.altenarCMSWidgets === undefined) {
      window.altenarCMSWidgets = new Map();
    }

    if (
      altenarContainerRef.current === null ||
      window.altenarCMSWidgets.has(key)
    ) {
      return;
    }

    if (window.altenarWSDK) {
      window.altenarCMSWidgets.set(key, {
        initialized: true,
        init: () => {}
      });
      addWidget(altenarContainerRef);
    } else {
      // will be called after the window.altenarWSDK.init call
      window.altenarCMSWidgets.set(key, {
        initialized: false,
        init: () => addWidget(altenarContainerRef)
      });
    }
  }, [key, altenarContainerRef, addWidget]);

  return { altenarContainerRef };
}

export function useAltenarWidget({
  enabled,
  integration,
  scriptSrc,
  numFormat,
  oddsFormat = 0,
  gameId
}: AltenarConfig) {
  const { lang, t } = useTranslation();
  const router = useRouter();
  const toast = useToast();
  const culture = altenarLanguageCodes[lang] || 'en-GB';
  const [isScreenMobile] = useMediaQuery('(max-width: 600px)');
  const [isScreenTablet] = useMediaQuery(
    '(min-width: 601px) and (max-width: 992px)'
  );
  const [isAltenarScriptReady, setIsAltenarScriptReady] =
    useState<boolean>(false);
  const [tokenAltenar, setTokenAltenar] = useState<string | undefined>();
  const { isLogged, isLoginFetching, getLicences } = useAuth();
  const [isGettingAltenarToken, setIsGettingAltenarToken] =
    useState<boolean>(true);
  const [isAltenarMounted, setIsAltenarMounted] = useState<boolean>(true);

  const getNewToken = useCallback(async (): Promise<{
    newToken: string;
    newLanguage: string;
  }> => {
    if (!enabled) return Promise.resolve({ newToken: '', newLanguage: '' });
    if (gameId === undefined || lang === undefined)
      return Promise.resolve({ newToken: '', newLanguage: '' });
    if (debug)
      console.log('useAltenarWidget - getNewToken', {
        enabled,
        gameId,
        lang,
        isScreenMobile,
        isScreenTablet
      });

    const launchParams: ApiGameLaunchRequest = {
      gameId,
      lang,
      demo: false,
      screenSizeType: isScreenMobile
        ? 'mobile'
        : isScreenTablet
        ? 'tablet'
        : 'desktop',
      exitUrl: ''
    };

    const {
      data: { launchUrl, error }
    } = await HTTP.post<ApiGameLaunchResponse>('game/launch', launchParams);

    if (error) throw new Error(error);
    if (!launchUrl) throw new Error('NO_LAUNCH_URL');

    const params = new URLSearchParams(launchUrl);
    const parsedToken = params.get('token');
    const parsedLanguage = params.get('lang');

    return {
      newToken: parsedToken || '',
      newLanguage: parsedLanguage || 'en-GB'
    };
  }, [enabled, gameId, lang, isScreenMobile, isScreenTablet]);

  const handleToken = useCallback(async () => {
    if (!enabled) return;
    if (debug) console.log('useAltenarWidget - handleToken', { enabled });

    try {
      const { newToken } = await getNewToken();
      setTokenAltenar(newToken);
    } catch (err) {
      toast({
        title: 'error',
        description: (err as Error).message,
        status: 'error',
        duration: 4000,
        position: 'top-right',
        isClosable: true
      });
    }
  }, [toast, getNewToken, enabled]);

  const mountAltenar = useCallback(() => {
    if (!enabled) return;
    if (debug) console.log('useAltenarWidget - mountAltenar', tokenAltenar);

    if (window.altenarWSDK) {
      window.altenarWSDK.init({
        culture: altenarLanguageCodes[lang] || 'en-GB',
        integration,
        numFormat,
        oddsFormat,
        token: tokenAltenar,
        dateFormat: {
          time: 'hh:mm',
          day: 'DD',
          date: 'DD/MM/YY',
          dateTime: 'DD/MM/YY \u2022 hh:mm',
          dayMonth: 'DD/MM',
          dayMonthTime: 'DD/MM \u2022 hh:mm'
        }
      });

      for (const [key, widget] of window.altenarCMSWidgets) {
        // ensure to launch altenar widget only once
        if (!widget.initialized) {
          widget.init();
          widget.initialized = true;
          window.altenarCMSWidgets.set(key, widget);
        }
      }

      window.altenarResetWidgets = function () {
        if (!window.altenarWSDK) return;

        for (const [key, widget] of window.altenarCMSWidgets) {
          window.altenarCMSWidgets.delete(key);
        }
      };

      if (tokenAltenar) {
        if (debug) console.log('SET ALTENAR  TOKEN', { tokenAltenar });

        window.altenarWSDK.set({
          token: tokenAltenar
        });
      }

      setIsAltenarMounted(true);
    }
  }, [enabled, tokenAltenar, integration, numFormat, oddsFormat, lang]);

  useEffect(() => {
    if (isLoginFetching || !enabled) return;

    if (isLogged) {
      // check if user has the licence F+
      const license = 'F+';
      getLicences?.().then((userLicences: UserLicencesType[]) => {
        if (
          userLicences &&
          !userLicences.some((x) => x.name === license && x.enabled)
        ) {
          toast({
            title: t('common:licenceNotAllowed', {
              name: license
            }),
            description: (
              <>
                <p>{t('common:toPlayThisGame')}</p>
                <Button
                  variant="outline"
                  style={{ marginTop: '1em' }}
                  onClick={() => router.push('/me/account/licences')}
                  data-testid="licenses-update-button"
                >
                  {t('common:updateMySettings')}
                </Button>
              </>
            ),
            status: 'warning',
            position: 'top',
            duration: null,
            isClosable: true
          });
          setIsGettingAltenarToken(false);
        } else {
          setIsGettingAltenarToken(true);
          handleToken().then(() => setIsGettingAltenarToken(false));
        }
      });
    } else {
      setIsGettingAltenarToken(true);
      if (window.altenarWSDK) {
        window.altenarWSDK.set({
          token: ''
        });
      }
      const timeoutId = setTimeout(() => setIsGettingAltenarToken(false), 500);
      return () => clearTimeout(timeoutId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // getLicences,
    t,
    router,
    toast,
    isLoginFetching,
    enabled,
    handleToken,
    isLogged,
    lang
  ]);

  useEffect(() => {
    if (
      !enabled ||
      isLoginFetching ||
      isGettingAltenarToken ||
      !isAltenarScriptReady
    )
      return;

    mountAltenar();
  }, [
    enabled,
    isLoginFetching,
    isGettingAltenarToken,
    isAltenarScriptReady,
    mountAltenar
  ]);

  // Reset altenar widgets when unmounting page using the widgets
  // this ensure to get widgets reinitialized when the component / page is mounted again
  // (ex: through navigator history for example)
  useEffect(() => {
    return () => {
      if (window.altenarWSDK && window.altenarResetWidgets) {
        window.altenarResetWidgets();
      }
    };
  }, []);

  return {
    tokenAltenar,
    altenarIntegration: integration,
    altenarScriptSrc: scriptSrc,
    isAltenarEnable: enabled,
    culture,
    numFormat,
    oddsFormat,
    isAltenarScriptReady,
    isAltenarMounted,
    setIsAltenarScriptReady
  };
}
