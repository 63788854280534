import { FC, MutableRefObject, useCallback } from 'react';
import { Box, BoxProps } from '@chakra-ui/react';
import { CMSContentBaseComponent } from 'types/api/ge-strapi/cms-page';
import { useAddWidget } from '@/hooks/useAltenarWidget';
import { useLoginModal } from '@/context/LoginModal';
import { useAuth } from '@/context/Auth';
import { useWallet } from '@/context/Wallet';
import { navigateToAltenarBetHistory } from '@/helpers/altenar';
import { useRouter } from 'next/router';

export const CMSAltenarBestSlip: FC<
  CMSContentBaseComponent & {
    betSlipPosition?: 'top-left' | 'bottom-right';
  } & Omit<BoxProps, 'id'>
> = ({
  __component,
  id,
  betSlipPosition = 'bottom-right',
  tokens,
  ...boxProps
}) => {
  const { onOpen } = useLoginModal();
  const { getBalance } = useWallet();
  const { locale } = useRouter();
  const { isLogged } = useAuth();
  const addWidget = useCallback(
    (altenarContainerRef: MutableRefObject<HTMLDivElement | null>) => {
      if (altenarContainerRef.current === null) return;

      window.altenarWSDK.addWidget({
        widget: 'WBetSlip',
        props: {
          betTypes: [0, 1, 2, 3],
          betShareTypes: [0, 1, 2, 3],
          // betShareURL: 'https://sb2widgetsstatic-altenar2.biahosted.com',
          enableBookPrint: true,
          enableExpansion: true,
          expand: false,
          variants: { BetSlip: 0, BetSlipHeader: 3 },
          onBetHistoryButtonClick: () => {
            navigateToAltenarBetHistory(locale);
          },
          onBetPlacement: (betIds: number[]) => {
            // console.log('WBetSlip -> onBetPlacement', betIds);
            getBalance?.();
          },
          // onBetPlacementError: (betparameter?: string) => {
          //   console.log('WBetSlip -> onBetPlacementError', betparameter);
          // },
          // onBetPrint: (args: { betIds: number[] }) => {
          //   console.log('WBetSlip -> onBetPrint', args);
          // },
          // onBetReservation: (reservationCode: string, totalStake: number) => {
          //   console.log('WBetSlip -> onBetReservation', {
          //     reservationCode,
          //     totalStake
          //   });
          // },
          // onBetSlipToggle: (isOpen: boolean) => {
          //   console.log('WBetSlip -> onBetSlipToggle', isOpen);
          // },
          onCashOut: () => {
            //console.log('WBetSlip -> onCashOut');
            getBalance?.();
          },
          // onEventSelect: (event: {
          //   id: number;
          //   liveTime?: string;
          //   sportId: number;
          //   catId: number;
          //   champId: number;
          // }) => {
          //   console.log('WBetSlip -> onEventSelect', event);
          // },
          // onInsufficientBalance: (params: { totalStake: number }) => {
          //   console.log('WBetSlip -> onInsufficientBalance', params);
          // },
          onSelectionCountChange: (count: number) => {
            // console.log('WBetSlip -> onSelectionCountChange', count);
            if (count === 0) {
              altenarContainerRef.current?.classList.add('hidden');
            } else {
              altenarContainerRef.current?.classList.remove('hidden');
            }
          },
          // onSelectionToggle: (e: any) => {
          //   console.log('WBetSlip -> onSelectionToggle', e);
          // },
          // onShareCode: (event: {
          //   message: string;
          //   code: string;
          //   link: string;
          // }) => {
          //   console.log('WBetSlip -> onShareCode', event);
          // },
          onSignInButtonClick: () => {
            if (!isLogged) {
              // console.log('WBetSlip -> onSignInButtonClick');
              onOpen();
            }
          }
        },
        container: altenarContainerRef.current,
        tokens
      });
    },
    [isLogged, onOpen, getBalance, locale, tokens]
  );

  const { altenarContainerRef } = useAddWidget(
    `${__component}-${id}`,
    addWidget
  );

  const getPositionStyle = (betSlipPosition: 'top-left' | 'bottom-right') => {
    switch (betSlipPosition) {
      default:
      case 'top-left':
        return {
          top: ['90px', '100px'],
          left: [0, 8]
        };
      case 'bottom-right':
        return {
          bottom: [
            isLogged ? '90px' : '60px',
            isLogged ? '80px' : '70px',
            isLogged ? '80px' : '70px',
            0
          ],
          right: [0]
        };
    }
  };

  return (
    <Box
      position={'fixed'}
      zIndex={'modal'}
      overflow={'auto'}
      maxHeight={['calc(100vh - 90px - 56px)', 'calc(100vh - 100px - 86px)']}
      {...getPositionStyle(betSlipPosition)}
      {...boxProps}
    >
      <Box
        ref={altenarContainerRef}
        data-testid="cms-altenar-bet-slip"
        w={['100vw', 400, 400]}
      />
    </Box>
  );
};
